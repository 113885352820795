<template>
  <div class="zq-vue-editor">
    <vue-editor
      ref="editorRef"
      @focus="onFocus"
      :class="{'zq--editor--toolbar-not-visible': !isToolbar,}"
      :name="name"
      v-model="html"
      :editorOptions="editorOptions"
    ></vue-editor>
    <CModal
      :show.sync="isShowModal"
      :title="'Please select your image'"
      centered
      :fade="true"
    >
      <div class="img-list-wrapper" v-if="filesList">
        <div class="img-list-item" v-for="file in filesList" :key=file.id>
          <span @click="handleImageAdded(file)" >{{ file.name }}</span>
          <img :src=file.uri height="30px" style="padding-left: 10px">
        </div>
      </div>
      <div v-else class="no-images-to-select">
        <span>No images to select</span>
      </div>
      <div slot="footer" class="zq--modal--footer-content">
        <CButton
          @click="closeModal"
          class="ml-2 zq--custom-button"
        >
          Cancel
        </CButton>
      </div>
    </CModal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { VueEditor } from 'vue2-editor';

export default {
  components: {
    VueEditor
  },
  props: {
    valueProp: String,
    options: Object,
    disabled: {
      type: Boolean,
      default: false
    },
    errorMessage: {
      type: String,
      default: 'Required'
    },
    placeholder: {
      type: String,
      default: 'Insert text here...'
    },
    isValid: {
      type: Boolean,
      default: true
    },
    isToolbar: {
      type: Boolean,
      default: false
    },
    name: {
      type: String,
      default: '',
    },
    isTranslation: {
      type: Boolean,
      default: false,
    },
    editorHeight: Number,
    editorOptionsProps : {
      type: Object,
      default() {
        return {
          modules: {
            toolbar: {
              container: [
                ['bold', 'italic', 'underline'],
                [{ list: 'ordered' }, { list: 'bullet' }],
                [
                  { align: '' },
                  { align: 'center' },
                  { align: 'right' },
                  { align: 'justify' }
                ],
                [{ color: [] }],
                ['customImgControl', 'code-block'],
                ['link'],
                ['clean'],
              ],
              handlers: {
                customImgControl: async () => {
                  this.filesList = await this.handleGetFileObjectsByQuery({
                    idArray: [],
                    queryRequest: {
                      must: [
                        {
                          queryField: 'mimeType',
                          queryValues: ['image/jpeg', 'image/png', 'image/svg'],
                        },
                      ],
                      sortBy: [{ queryField: 'created', order: 'Desc' }],
                      limit: 999,
                    },
                  });

                  this.openModal();
                }
              }
            }
          }
        }
      }
    }
  },
  data() {
    return {
      html: '',
      editorOptions: this.editorOptionsProps,
      filesList: null,
      isShowModal: false,
      quill: null,
      focusedElement: null
    };
  },
  computed: {
    ...mapGetters('theme', ['theme']),
  },
  created() {
    if (this.valueProp) {
      this.html = this.valueProp;
    }
  },
  mounted() {
    const editorContainer = this.$refs.editorRef.$el.querySelector('.ql-container.ql-snow');

    if (editorContainer && this.editorHeight) {
      editorContainer.style.height = `${this.editorHeight}px`;
    }
  },
  methods: {
    ...mapActions('files', ['handleGetFileObjectsByQuery']),
    closeModal() {
      this.isShowModal = false;
    },
    openModal() {
      this.isShowModal = true;
    },
    onFocus(quill) {
      this.quill = quill;
    },
    async handleImageAdded(file) {
      const Editor = this.quill;
      const cursorLocation = this.quill.getSelection(true).index;

      if (Editor) {
        await Editor.editor.insertEmbed(cursorLocation, 'image', file.uri);
        await Editor.insertText(0, ' ', true);
      }

      this.closeModal();
    },
    toggleContenteditable(state) {
      const editor = this.$refs.wysiwygEditor?.$el?.childNodes[1];
      if (editor) {
        editor.setAttribute('contenteditable', state);
      }
    },
    handleEnter() {
      if (!this.disabled) {
        this.toggleContenteditable(true);
      }
    },
    handleLeave() {
      this.toggleContenteditable(false);
    },
  },
  watch: {
    valueProp(val) {
      this.html = val;
    },
    html(val) {
      if (this.name !== '' && this.isTranslation) {
        this.$emit('change', { name: this.name, value: val });
      } else {
        this.$emit('change', val);
      }
    }
  }
};
</script>
<style lang="scss">
.zq-vue-editor {
 width: 100%;

  .ql-editor {
    width: inherit;
    min-width: 253px;
  }

  .modal-content {
    border-radius: var(--zq--border-radius) !important;

    .modal-body {
      background: #fff !important;
      max-height: 400px;
      overflow-y: scroll;
      overflow-x: hidden;

      &::-webkit-scrollbar {
        height: 7px;
        width: 7px;
        background-color: #f0f0f0;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 3px;
        background-color: #e6e6e6;
      }
    }
  }

  .ql-snow .ql-editor pre.ql-syntax {
    background-color: #768192 !important;
  }

  .ql-toolbar.ql-snow {
    border: none !important;
    border-bottom: 1px solid #ccc !important;
  }

  .ql-container.ql-snow {
    border: none !important;
    height: 200px;
  }

  .ql-editor {
    min-height: inherit;
  }

  .zq--editor--toolbar-not-visible {
    .ql-toolbar {
      display: none;
    }
  }

  .quillWrapper {
    background: #fff;
    border-radius: var(--zq--border-radius);
    border: none;
  }

  .ql-customImgControl {
    display: flex;
    position: relative;

    &:after {
      content: url("../../../assets/icons/pictureIcon.svg");
      position: absolute;
      top: 20%;
      left: 10%;
    }
  }

  .img-list-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;

    .img-list-item {
      padding: 5px 0;
      cursor: pointer;

      &:hover {
        font-weight: bold;
      }
    }
  }

  .no-images-to-select {
    display: flex;
    width: 100%;
    height: 100px;
    align-items: center;
    justify-content: center;
    font-size: 17px;
  }

}

.zq--editor {
  .zq--editor--toolbar--visible {
    .editr--toolbar {
      display: flex;
      padding: 5px;

      a {
        background: #fff;
        margin: 2px;
      }
    }

    .editr--content {
      background: #fff;
      resize: vertical;
      border-top-left-radius: 0;
      border-top-right-radius: 0;

    }
  }

  //  SET PLACEHOLDER -> mouseleave
  .editr--content[contenteditable=false]:empty:before {
    content: attr(placeholder);
    color: rgba(0, 0, 0, 0.3);
    display: block;
  }

  //
  .editr--toolbar {
    flex-wrap: wrap;
    height: max-content;
    display: none;
    border-top-left-radius: var(--zq--border-radius);
    border-top-right-radius: var(--zq--border-radius);

    .vw-btn-separator {
      display: none;
    }

    div {
      flex: 1;
    }
  }

  .editr {
    border-radius: var(--zq--border-radius);
    box-shadow: 0 1px 10px 0 rgba(150, 109, 103, 0.1);
    border: none;
  }

  .editr--content {
    background: #fff;
    resize: vertical;
    border-radius: var(--zq--border-radius);
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
    padding: 8px 16px;
    overflow-wrap: break-word;

    &:before {
      font-style: italic;
      color: #282f37;
    }
  }

  // ERROR
  .error-validation--editor {
    border: 1px solid #d14334;
  }

  &--error-msg {
    font-size: 11px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.27;
    letter-spacing: normal;
    color: #d14334;
  }

  // DISABLED
  &--disabled {
    .editr--content {
      background: var(--zq--disabled-background);
      color: #768192;
    }

    .btn.zq--custom-button.zq--custom-button--responsive.zq--custom-button--responsive__icon {
      svg {
        fill: black;
        color: black;
      }

      .zq--editor--toggle-label {
        color: black;
      }
    }
  }
}
</style>